exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about_us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-access-qualified-talent-tsx": () => import("./../../../src/pages/access_qualified_talent.tsx" /* webpackChunkName: "component---src-pages-access-qualified-talent-tsx" */),
  "component---src-pages-advanced-manufacturing-tsx": () => import("./../../../src/pages/advanced_manufacturing.tsx" /* webpackChunkName: "component---src-pages-advanced-manufacturing-tsx" */),
  "component---src-pages-create-new-talent-tsx": () => import("./../../../src/pages/create_new_talent.tsx" /* webpackChunkName: "component---src-pages-create-new-talent-tsx" */),
  "component---src-pages-healthcare-tsx": () => import("./../../../src/pages/healthcare.tsx" /* webpackChunkName: "component---src-pages-healthcare-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-cybersecurity-tsx": () => import("./../../../src/pages/it_cybersecurity.tsx" /* webpackChunkName: "component---src-pages-it-cybersecurity-tsx" */),
  "component---src-pages-learner-outcomes-2023-tsx": () => import("./../../../src/pages/learner-outcomes-2023.tsx" /* webpackChunkName: "component---src-pages-learner-outcomes-2023-tsx" */),
  "component---src-pages-palette-tsx": () => import("./../../../src/pages/palette.tsx" /* webpackChunkName: "component---src-pages-palette-tsx" */),
  "component---src-pages-skilled-trades-tsx": () => import("./../../../src/pages/skilled_trades.tsx" /* webpackChunkName: "component---src-pages-skilled-trades-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank_you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-upskill-existing-talent-tsx": () => import("./../../../src/pages/upskill_existing_talent.tsx" /* webpackChunkName: "component---src-pages-upskill-existing-talent-tsx" */)
}

